// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-blog-editor-js": () => import("./../../../src/pages/admin/blog-editor.js" /* webpackChunkName: "component---src-pages-admin-blog-editor-js" */),
  "component---src-pages-admin-blog-js": () => import("./../../../src/pages/admin/blog.js" /* webpackChunkName: "component---src-pages-admin-blog-js" */),
  "component---src-pages-admin-generate-account-js": () => import("./../../../src/pages/admin/generate-account.js" /* webpackChunkName: "component---src-pages-admin-generate-account-js" */),
  "component---src-pages-admin-news-js": () => import("./../../../src/pages/admin/news.js" /* webpackChunkName: "component---src-pages-admin-news-js" */),
  "component---src-pages-admin-pict-manager-js": () => import("./../../../src/pages/admin/pict-manager.js" /* webpackChunkName: "component---src-pages-admin-pict-manager-js" */),
  "component---src-pages-admin-tutorials-editor-js": () => import("./../../../src/pages/admin/tutorials-editor.js" /* webpackChunkName: "component---src-pages-admin-tutorials-editor-js" */),
  "component---src-pages-admin-tutorials-js": () => import("./../../../src/pages/admin/tutorials.js" /* webpackChunkName: "component---src-pages-admin-tutorials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-user-account-js": () => import("./../../../src/pages/user/account.js" /* webpackChunkName: "component---src-pages-user-account-js" */),
  "component---src-pages-user-email-verify-js": () => import("./../../../src/pages/user/email-verify.js" /* webpackChunkName: "component---src-pages-user-email-verify-js" */),
  "component---src-pages-user-password-recovery-js": () => import("./../../../src/pages/user/password-recovery.js" /* webpackChunkName: "component---src-pages-user-password-recovery-js" */),
  "component---src-pages-user-signin-js": () => import("./../../../src/pages/user/signin.js" /* webpackChunkName: "component---src-pages-user-signin-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

